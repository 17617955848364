<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Parametry - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>

        <a-form-item
          label="Název"
          :validate-status="error('ple_name') ? 'error' : ''"
          :help="error('ple_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['ple_name', {rules: [{max: 80, message: 'Název nemůže být delší než 80 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Vyber hodnoty"
          :validate-status="error('values') ? 'error' : ''"
          :help="error('values') || ''"
        >
          <a-select placeholder="-- Vyber hodnoty --" mode="tags" :options="values.map(x => { return { value: x.vue_id.toString(), label: x.languages.length > 0 ? '# ' + x.vue_id + ' | ' + x.languages[0].pivot.vle_value : '# ' + x.vue_id + ' | není nastaveno' } })" v-decorator="['values', {rules: [{required: true, message: 'Hodnoty musí být vyplněné!'}]}]"></a-select>
        </a-form-item>

        <a-form-item
          label="Jednotka"
          :validate-status="error('ple_unit') ? 'error' : ''"
          :help="error('ple_unit') || ''"
        >
          <a-input placeholder="Jednotka" v-decorator="['ple_unit', {rules: [{max: 10, message: 'Jednotka nemůže být delší než 10 znaků!'}]}]"/>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

        <a-form-item label="Jsou hodnoty číslice?">
          <a-checkbox v-decorator="['prr_is_numeric', { valuePropName: 'checked', initialValue: false }]"/>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

function typeOfNaN(x) {
  return Number.isNaN(x) || isNaN(x)
}

export default {
  components: { ActionTools, LanguageTab },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data: function () {
    return {
      hasErrors,
      dependencies: ['values'],
      id: this.$route.params.id,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/parameter',
          title: 'Seznam',
        },
      ],
      item: {
        values: {
          loading: false,
          active: [],
        },
        languages: [],
      },
      loaded: [],
    }
  },
  computed: {
    language: function() {
      return this.$store.getters['language/active']
    },
    detail: function() {
      return this.$store.getters['parameter/getDetail']
    },
    values: function() {
      return this.$store.getters['value/currentLanguage']
    },
  },
  methods: {
    refreshComponent(id) {
      this.id = id
      this.item = {
        values: {
          loading: false,
          active: [],
        },
        languages: [],
      }
      this.loaded = []
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    initData() {
      this.loading = true
      this.dependencies.forEach((value) => {
        this.item[value].loading = true
      })
      Promise.all([
        this.$store.dispatch('value/getList'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.dependencies.forEach((value) => {
            this.item[value].loading = false
          })
          this.loaded.push(this.language)
        })
    },
    initDetail() {
      this.loading = true
      this.$store.dispatch('parameter/getOne', this.id)
        .then(() => {
          const oldItem = this.item
          this.item = Object.assign({}, this.detail)
          this.item.values = {
            loading: false,
            active: oldItem.values.active,
          }
          this.item.values.active.push({
            lge_id: this.language,
            items: [],
          })
          const currentDetailLang = this.$store.getters['parameter/currentLanguageDetail']
          this.form.setFieldsValue({
            ple_name: currentDetailLang.ple_name,
            ple_unit: currentDetailLang.ple_unit,
            values: this.detail.values,
            prr_is_numeric: this.item.prr_is_numeric,
          })
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    getData() {
      const values = []
      if (this.form.getFieldValue('values') !== undefined) {
        this.form.getFieldValue('values').forEach(x => {
          if (!typeOfNaN(x) && this.values.find(y => y.vue_id === parseInt(x))) {
            values.push(parseInt(x))
          }
        })
      }
      return {
        prr_is_numeric: this.form.getFieldValue('prr_is_numeric'),
        languages: this.item.languages.filter(x => x.ple_name !== undefined && x.ple_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            ple_name: x.ple_name,
            ple_unit: x.ple_unit === undefined ? null : x.ple_unit,
          }
        }),
        values: {
          new: {
            count: this.item.values.active[0].items.length,
            languages: this.item.values.active[0].items.length === 0 ? [] : this.item.values.active,
          },
          existing: values,
        },
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('parameter/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleChange(oldLanguage) {
      const foundObj = { languages: false, values: false }
      const newObj = {
        languages: {
          lge_id: oldLanguage,
          ple_name: this.form.getFieldValue('ple_name'),
          ple_unit: this.form.getFieldValue('ple_unit'),
        },
        values: {
          lge_id: oldLanguage,
          items: this.form.getFieldValue('values') === undefined ? [] : this.form.getFieldValue('values').filter(x => { return !(!typeOfNaN(x) && this.values.find(y => y.vue_id === parseInt(x))) }),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.languages
          foundObj.languages = true
          return true
        }
      })
      this.item.values.active.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.values.active[key] = newObj.values
          foundObj.values = true
          return true
        }
      })
      if (!foundObj.languages) {
        this.item.languages.push(newObj.languages)
      }
      if (!foundObj.values) {
        this.item.values.active.push(newObj.values)
      }
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initData()
    }
  },
  watch: {
    '$route.params.id'(newId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initData()
      }
    },
    language(newValue, oldValue) {
      if (oldValue !== null && this.loaded.includes(oldValue)) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (!this.loaded.includes(this.language)) {
          this.initData()
        } else {
          const foundObj = { languages: this.item.languages.find(x => x.lge_id === newValue), values: this.item.values.active.find(x => x.lge_id === newValue) }
          let newObj
          if (foundObj.languages === undefined) {
            newObj = {
              ple_name: undefined,
              ple_unit: undefined,
            }
          } else {
            newObj = {
              ple_name: foundObj.languages.ple_name,
              ple_unit: foundObj.languages.ple_unit,
            }
          }
          newObj.values = this.form.getFieldValue('values') === undefined ? [] : this.form.getFieldValue('values').filter(x => { return !typeOfNaN(x) && this.values.find(y => y.vue_id === parseInt(x)) })
          if (foundObj.values !== undefined) {
            newObj.values = newObj.values.concat(foundObj.values.items)
          }
          this.form.setFieldsValue(newObj)
        }
        this.form.validateFields()
      }
    },
  },
}
</script>
